import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';
import { Babysitter } from 'npx-family-happy-common';
import { Injectable } from '@angular/core';
import { map, Observable, BehaviorSubject, forkJoin, catchError } from 'rxjs';
import { cellFormatter, emailFormatter } from 'src/app/shared/utils.functions';

@Injectable({
  providedIn: 'root'
})
export class BabySitterService {
  baseURL = `${environment.baseURL}/babysitters`;
  babysitterList = new BehaviorSubject<Babysitter[]>([]);


  constructor(private http: HttpClient, private firestore: AngularFirestore) { }

  checkEmptyField(field: string) {
    return field !== '' ? field : 'unknown';
  }

  getFiveSuggestions(search: string, province: string): Observable<Babysitter[]> {
    let request = {
      age_lower: null,
      age_upper: null,
      car: false,
      cleaning: false,
      cluster1: false,
      cluster2: false,
      cluster3: false,
      cluster4: false,
      contract: null,
      cooking: false,
      cv: false,
      days: null,
      disostruzione: false,
      doc_ref: null,
      email: null,
      english: false,
      fhsub: false,
      french: false,
      german: false,
      greenpass: false,
      homework: false,
      hours: null,
      italian: false,
      licence: false,
      limit: 5,
      minimum_evaluation: false,
      name: search ? search.replace(`'`, `''`) : '',
      not_selected: false,
      not_smoker: false,
      one_ref: false,
      page: 1,
      phone: null,
      province: province !== '' ? province : null,
      spanish: false,
      taxi: false
    };

    return this.http.post<Babysitter[]>(this.baseURL + '/find', request).pipe(map((babysitterList) => {
      return babysitterList.filter(babysitter => babysitter.rating.dangerous === false).slice(0, 5);
    }));
  }

  getBabySitter(filters: any, limit: number): Observable<Babysitter[]> {
    const request = this.getBabysitterFilters(filters, limit);

    this.http.post<Babysitter[]>(this.baseURL + '/find', request).subscribe((data) => {
      this.babysitterList.next(data);
    });

    return this.babysitterList;
  }

  getWhatsappFilteredTargets(filters: any, latitude: number, longitude: number, range: number | null = 5, limit = 10): any {
    if (filters) {

      let request = {
        age_lower: filters.fromAge ?? null,
        age_upper: filters.toAge ?? null,
        car: filters.car ?? false,
        cleaning: filters.cleaning ?? false,
        cluster1: filters.exp.cluster1 ?? false,
        cluster2: filters.exp.cluster2 ?? false,
        cluster3: filters.exp.cluster3 ?? false,
        cluster4: filters.exp.cluster4 ?? false,
        contract: filters.contract === '' ? null : (filters.contract ?? null),
        cooking: filters.cooking ?? false,
        cv: filters.fullCV ?? false,
        days: filters.days === '' ? null : (filters.days ?? null),
        disostruzione: filters.disostruzione ?? false,
        doc_ref: filters.docRef ?? null,
        email: filters.info.email ? emailFormatter(filters.info.email as string) : null,
        english: filters.lang.english ?? false,
        englishAdvanced: filters.lang.englishAdvanced ?? false,
        fhsub: filters.fhsub ?? false,
        french: filters.lang.french ?? false,
        frenchAdvanced: filters.lang.frenchAdvanced ?? false,
        german: filters.lang.german ?? false,
        germanAdvanced: filters.lang.germanAdvanced ?? false,
        greenpass: filters.greenpass ?? false,
        homework: filters.homework ?? false,
        hours: filters.hours === '' ? null : (filters.hours ?? null),
        italian: filters.lang.italian ?? false,
        italianAdvanced: filters.lang.italianAdvanced ?? false,
        licence: filters.licence ?? false,
        limit,
        minimum_evaluation: filters.rating.overall ?? false,
        not_selected: filters.ignored ?? false,
        not_smoker: filters.smoker ?? false,
        one_ref: filters.ref ?? false,
        page: filters.page ?? 1,
        phone: filters.info.phone ? cellFormatter(filters.info.phone as string) : null,
        province: filters.province ?? null,
        spanish: filters.lang.spanish ?? false,
        spanishAdvanced: filters.lang.spanishAdvanced ?? false,
        taxi: filters.taxi ?? false,
        range: range! ?? null,
        latitude: latitude ?? null,
        longitude: longitude ?? null,
        name: null
      }
      return this.http.post<any>(this.baseURL + '/get-whatsapp-filtered-target', request)
    }
  }

  getListOfBabysitters(ids: string[]) {
    let babysitters: any = {};
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      babysitters[id] = this.getSingleBabysitter(id).pipe(catchError((error) => { return error }));
    }
    return forkJoin(babysitters);
  }

  getSingleBabysitter(id: string) {
    let request = {
      age_lower: null,
      age_upper: null,
      car: false,
      cleaning: false,
      cluster1: false,
      cluster2: false,
      cluster3: false,
      cluster4: false,
      contract: null,
      cooking: false,
      cv: false,
      days: null,
      disostruzione: false,
      doc_ref: id,
      email: null,
      english: false,
      fhsub: false,
      french: false,
      german: false,
      greenpass: false,
      homework: false,
      hours: null,
      italian: false,
      licence: false,
      limit: 1,
      minimum_evaluation: false,
      name: null,
      not_selected: false,
      not_smoker: false,
      one_ref: false,
      page: 1,
      phone: null,
      province: null,
      spanish: false,
      taxi: false
    };

    return this.http.post<Babysitter[]>(this.baseURL + '/find', request);
  }

  getOneBabysitter(id: string) {
    let request = {
      age_lower: null,
      age_upper: null,
      car: false,
      cleaning: false,
      cluster1: false,
      cluster2: false,
      cluster3: false,
      cluster4: false,
      contract: null,
      cooking: false,
      cv: false,
      days: null,
      disostruzione: false,
      doc_ref: id,
      email: null,
      english: false,
      fhsub: false,
      french: false,
      german: false,
      greenpass: false,
      homework: false,
      hours: null,
      italian: false,
      licence: false,
      limit: 1,
      minimum_evaluation: false,
      name: null,
      not_selected: false,
      not_smoker: false,
      one_ref: false,
      page: 1,
      phone: null,
      province: null,
      spanish: false,
      taxi: false
    };

    return this.http.post<Babysitter[]>(this.baseURL + '/find', request).pipe(map((res: Babysitter[]) => res.length ? res[0] : null));
  }

  updateBabySitter(requestData: Babysitter) {
    return this.http.post(this.baseURL + '/import', requestData)
  }

  async getFirebaseDocRef(email: string) {
    const doc = await this.firestore.collection('babysitter').add({ placeholder: email });
    return doc.id;
  }

  createBabySitter(requestData: Babysitter) {
    return this.http.post(this.baseURL + '/import', requestData);
  }

  deleteBabySitter(id: string) {
    // Remember to delete storage data
    return this.http.delete(this.baseURL + '/delete/' + id);
  }

  getWhatsappTargets(province: string, latitude: number | null, longitude: number | null, range: number | null) {
    let filters = {
      province: province,
      latitude: latitude,
      longitude: longitude,
      range: range
    }
    const params = [
      `${this.convertFilterObjectToQueryParam(filters)}`
    ].join('&');
    return this.http.get<string[]>(`${this.baseURL}/get-whatsapp-target?${params}`);
  }



  /* Utilities methods */
  private convertFilterObjectToQueryParam(filters: any) {
    let queryString = `province=${encodeURIComponent(filters.province)}`;
    // Object.entries(filters).forEach((entry: [string, any | null]) => {
    //   if (entry[1] !== null &&
    //     ((typeof entry[1] === 'string' && entry[1] !== '') ||
    //       (typeof entry[1] === 'boolean' && entry[1] !== false) ||
    //       (typeof entry[1] === 'number' && entry[1] >= 0))) {
    //     if (entry[0] === 'dateFrom' || entry[0] === 'dateTo') {
    //       const dateValue = `${(entry[1] as string).replace('T', ' ')}:00`;
    //       queryString += `${queryString === '' ? '' : '&'}${entry[0]}=${dateValue}`
    //     } else if (entry[0] === 'seller' && entry[1] === 'default') {

    //     } else if (entry[0] === 'amount') {
    //       queryString += `${queryString === '' ? '' : '&'}${entry[0]}=${+entry[1]*100}`
    //     } else {
    //       queryString += `${queryString === '' ? '' : '&'}${entry[0]}=${encodeURIComponent(entry[1])}`
    //     }
    //   }
    // })
    if (filters.latitude !== null && filters.longitude !== null && filters.range !== null) {
      queryString += `&latitude=${filters.latitude}&longitude=${filters.longitude}&range=${filters.range}`;
    }

    return queryString;
  }

  // It converts an object to a string. e.g.: {sun: true, mon: false, tue: true} -> 'sun-tue'
  fromAvailObjectToString(object: any, type: string) {
    let result = '';
    if (object) {
      switch (type) {
        case 'hours':
          result += object['mor'] ? 'mor' : '';
          result += object['aft'] ? (result !== '' ? '-aft' : 'aft') : '';
          result += object['eve'] ? (result !== '' ? '-eve' : 'eve') : '';
          return result;
        case 'days':
          result += object['sun'] ? 'sun' : '';
          result += object['mon'] ? (result !== '' ? '-mon' : 'mon') : '';
          result += object['tue'] ? (result !== '' ? '-tue' : 'tue') : '';
          result += object['wed'] ? (result !== '' ? '-wed' : 'wed') : '';
          result += object['thu'] ? (result !== '' ? '-thu' : 'thu') : '';
          result += object['fri'] ? (result !== '' ? '-fri' : 'fri') : '';
          result += object['sat'] ? (result !== '' ? '-sat' : 'sat') : '';
          return result;
        case 'contract':
          result += object['occ'] ? 'occ' : '';
          result += object['part'] ? (result !== '' ? '-part' : 'part') : '';
          result += object['full'] ? (result !== '' ? '-full' : 'full') : '';
          return result;
        default:
          return '';
      }
    } else {
      return result;
    }
  }

  // It converts a string to an object (hour, contract or days property of Babysitter). e.g.: 'sun-tue' -> {sun: true, mon: false, tue: true}
  fromStringToAvailObject(origin: string, type: string) {
    if (origin) {
      let keys = origin.split('-') || [];
      switch (type) {
        case 'hours':
          return {
            mor: keys.findIndex(item => item === 'mor') !== -1,
            aft: keys.findIndex(item => item === 'aft') !== -1,
            eve: keys.findIndex(item => item === 'eve') !== -1,
          }
        case 'days':
          return {
            sun: keys.findIndex(item => item === 'sun') !== -1,
            mon: keys.findIndex(item => item === 'mon') !== -1,
            tue: keys.findIndex(item => item === 'tue') !== -1,
            wed: keys.findIndex(item => item === 'wed') !== -1,
            thu: keys.findIndex(item => item === 'thu') !== -1,
            fri: keys.findIndex(item => item === 'fri') !== -1,
            sat: keys.findIndex(item => item === 'sat') !== -1,
          };
        case 'contract':
          return {
            occ: keys.findIndex(item => item === 'occ') !== -1,
            part: keys.findIndex(item => item === 'part') !== -1,
            full: keys.findIndex(item => item === 'full') !== -1,
          };
        default:
          return {
            occ: false,
            part: false,
            full: false,
          };
      }
    } else {
      switch (type) {
        case 'hours':
          return {
            mor: false,
            aft: false,
            eve: false,
          }
        case 'days':
          return {
            sun: false,
            mon: false,
            tue: false,
            wed: false,
            thu: false,
            fri: false,
            sat: false,
          };
        case 'contract':
          return {
            occ: false,
            part: false,
            full: false,
          };
        default:
          return undefined;
      }
    }
  }

  exportBabysittersFromDB(filters: any) {
    const request = this.getBabysitterFilters(filters, 50);

    if (request) {
      return this.http.post(this.baseURL + '/export-babysitters-to-excel', request, { responseType: 'blob' });
    }

    throw new Error('No filters provided');
  }

  private getBabysitterFilters(filters: any, limit: number) {
    if (filters) {
      if (!filters.info) {
        filters.info = {};
      }
      if (!filters.avail) {
        filters.avail = {};
      }
      if (!filters.exp) {
        filters.exp = {};
      }
      if (!filters.lang) {
        filters.lang = {};
      }
      if (!filters.rating) {
        filters.rating = {};
      }
      if (!filters.engagement) {
        filters.engagement = {};
      }
      if (!filters.cert) {
        filters.cert = {};
      }

      return {
        age_lower: filters.fromAge ?? null,
        age_upper: filters.toAge ?? null,
        car: filters.avail.car ?? false,
        cleaning: filters.avail.cleaning ?? false,
        cluster1: filters.exp.cluster1 ?? false,
        cluster2: filters.exp.cluster2 ?? false,
        cluster3: filters.exp.cluster3 ?? false,
        cluster4: filters.exp.cluster4 ?? false,
        contract: filters.avail.contract ?? null,
        cooking: filters.avail.cooking ?? false,
        cv: filters.fullCV ?? false,
        days: filters.avail.days ?? null,
        disostruzione: filters.cert.disostruzione ?? false,
        doc_ref: filters.docRef ?? null,
        email: filters.info.email ? emailFormatter(filters.info.email as string) : null,
        english: filters.lang.english ?? false,
        englishAdvanced: filters.lang.englishAdvanced ?? false,
        fhsub: filters.info.fhsub ?? false,
        french: filters.lang.french ?? false,
        frenchAdvanced: filters.lang.frenchAdvanced ?? false,
        german: filters.lang.german ?? false,
        germanAdvanced: filters.lang.germanAdvanced ?? false,
        greenpass: filters.avail.greenpass ?? false,
        homework: filters.avail.homework ?? false,
        hours: filters.avail.hours ?? null,
        italian: filters.lang.italian ?? false,
        italianAdvanced: filters.lang.italianAdvanced ?? false,
        licence: filters.avail.licence ?? false,
        limit,
        minimum_evaluation: filters.rating.overall ?? false,
        name: filters.info.name ? filters.info.name.replace(`'`, `''`) : null,
        not_selected: filters.engagement.ignored ?? false,
        not_smoker: filters.avail.smoker ?? false,
        one_ref: filters.ref ?? false,
        page: filters.page ?? 1,
        phone: filters.info.phone ? cellFormatter(filters.info.phone as string) : null,
        province: filters.province ?? null,
        spanish: filters.lang.spanish ?? false,
        spanishAdvanced: filters.lang.spanishAdvanced ?? false,
        taxi: filters.avail.taxi ?? false,
        range: filters.range * 1000,
        latitude: filters.latitude ?? null,
        longitude: filters.longitude ?? null
      }
    }
    return undefined;
  }
}
