<div class="bigger-container">
  <div class="left-section">
    <p [ngClass]="{'selected': step === 0}" (click)="changeTab(0)">Anagrafica</p>
    <p [ngClass]="{'selected': step === 1}" (click)="changeTab(1)">Esperienza</p>
    <p [ngClass]="{'selected': step === 2}" (click)="changeTab(2)">Referenze</p>
    <p [ngClass]="{'selected': step === 3}" (click)="changeTab(3)">Formazione</p>
    <p [ngClass]="{'selected': step === 4}" (click)="changeTab(4)">Disponibilità</p>
    <p [ngClass]="{'selected': step === 5}" (click)="changeTab(5)">Valutazione</p>
    <p [ngClass]="{'selected': step === 6}" (click)="changeTab(6)">Coinvolgimento</p>
  </div>
  <div class="right-section">
    <div class="back-button" (click)="goBack()">
      <i class="material-icons">arrow_back</i>
      <p>Torna alla lista</p>
    </div>
    <div class="smaller-container">
      <p class="smaller-text">{{isNew ? 'Crea' : 'Modifica'}} Colf</p>
      <div class="smaller-bar"></div>
    </div>
    <form [formGroup]="form" class="mt-5 px-3">
      <div *ngIf="step === 0" class="grid" formGroupName="info">
        <div>
          <label class="label" for="name">Nome<span class="red">*</span></label>
          <input type="text" id="name" formControlName="name">
        </div>
        <div>
          <label class="label" for="surname">Cognome<span class="red">*</span></label>
          <input type="text" id="surname" formControlName="surname">
        </div>
        <div>
          <label class="label" for="email">Email<span class="red">*</span></label>
          <input type="text" id="email" formControlName="email">
        </div>
        <div>
          <label class="label" for="phone">Cellulare<span class="red">*</span></label>
          <input type="text" id="phone" formControlName="phone">
        </div>
        <div>
          <label class="label" for="address">Indirizzo</label>
          <input type="text" id="address" formControlName="address">
        </div>
        <div>
          <label class="label" for="city">Città</label>
          <input type="text" id="city" formControlName="city">
        </div>
        <div>
          <label class="label" for="zone">Zona</label>
          <input type="text" id="zone" formControlName="zone">
        </div>
        <div>
          <label class="label" for="cap">CAP</label>
          <input type="text" id="cap" formControlName="cap">
        </div>
        <div>
          <label class="label" for="age">Età</label>
          <input type="number" id="age" formControlName="age" min="0" max="99">
        </div>
        <div>
          <label class="label" for="birthDate">Data di Nascita</label>
          <input type="date" id="birthDate" formControlName="birthDate">
        </div>
        <div>
          <label class="label" for="provincia">Provincia di interesse</label>
          <app-province-filter [provincia]="form.get('info.provincia')?.value" (valueChange)="updateProvincia($event)"></app-province-filter>
        </div>
        <div>
          <label class="label" for="fiscalcode">Codice Fiscale</label>
          <input type="text" id="fiscalcode" style="text-transform: uppercase;" formControlName="fiscalcode">
        </div>
        <div>
          <label class="label" for="iban">IBAN</label>
          <input type="text" id="iban" formControlName="iban">
        </div>
        <div>
          <label class="label">Video</label>
          <input type="text" formControlName="video">
        </div>
        <div>
          <label class="label">Certificato</label>
          <div>
            <a [href]="colf && colf.certificate"
              *ngIf="colf && colf.certificate && colf.certificate !== ''" target="_blank" class="tertiary-button">Scarica</a>
            <p *ngIf="!colf || !colf.certificate || colf.certificate === ''" style="grid-column: 1/3">La colf non ha
              ancora ottenuto la certificazione.</p>
          </div>
        </div>
        <!-- <div>
          <label class="label">Certificato F+H</label>
          <p
            *ngIf="!(!isNew && nurse?.rating ? ((nurse.rating.overall >= 6) && nurse?.certificates?.disostruzione && !nurse?.rating?.dangerous) : false)">
            La colf non soddisfa i requisiti per la certificazione.</p>
          <div
            *ngIf="!isNew && nurse?.rating ? ((nurse.rating.overall >= 6) && nurse?.certificates?.disostruzione && !nurse?.rating?.dangerous) : false">
            <button class="secondary-button" (click)="openCertificatePage()">Crea</button>
            <a *ngIf="!isNew && nurse?.certificate" class="tertiary-button" download
              href="{{nurse.certificate}}" target="_blank">Scarica</a>
          </div>
        </div> -->
        <div>
          <label class="label">CV Family Happy</label>
          <div style="grid-template-columns: repeat(3, 1fr)">
            <div class="upload-button-container" style="display: block;">
              <button class="secondary-button" (click)="generateCV(true)">Crea</button>
            </div>
            <a *ngIf="colf ? (colf.info ? colf.info.resumeHidden : false) : false" class="tertiary-button" download
              [href]="colf ? (colf.info ? colf.info.resumeHidden : '') : ''" target="_blank">Scarica</a>
            <a *ngIf="colf ? (colf.info ? colf.info.resumeHidden : false) : false" class="tertiary-button"
              (click)="cvPreview()" [ngStyle]="{ 'opacity': generatePages ? '50%' : '100%' }">Invia</a>
          </div>
        </div>
        <div>
          <label class="label">CV</label>
          <div>
            <div class="upload-button-container" style="display: block;">
              <button class="secondary-button">Carica</button>
              <input type="file" accept=".pdf" (change)="uploadFile($event, 'info.resume')">
            </div>
            <a *ngIf="colf ? (colf.info ? colf.info.resume : false) : false" class="tertiary-button" download
              [href]="colf ? (colf.info ? colf.info.resume : '') : ''" target="_blank">Scarica</a>
          </div>
        </div>
        <div *ngIf="!isNew; else elseBlock">
          <label class="label">Foto</label>
          <div>
            <div class="upload-button-container" style="display: block;">
              <button class="secondary-button">Carica</button>
              <input id="image-selection" type="file" accept="image/*" (change)="openModal($event)">
            </div>
            <a *ngIf="colf ? (colf.info ? colf.info.photo : false) : false" class="tertiary-button" download
              [href]="colf ? (colf.info ? colf.info.photo : '') : ''" target="_blank">Scarica</a>
          </div>
        </div>
        <ng-template #elseBlock>
          <div>
            <label class="label">Foto</label>
            <p id="foto">Puoi aggiungere la foto nella sezione "modifica" del profilo</p>
          </div>
        </ng-template>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="stopWhatsapp">Stop Whatsapp</label>
          <input type="checkbox" id="stopWhatsapp" formControlName="stopWhatsapp">
        </div>
        <div class="input-field">
          <label for="subscription" class="label">Stato Abbonamento</label>
          <input type="text" formControlName="subscription" id="subscription" readonly>
        </div>
        <div class="input-field">
          <label class="label" for="expDate">Scadenza Abbonamento</label>
          <input type="date" id="expDate" formControlName="expDate" readonly>
        </div>
        <div class="input-field">
          <label for="manualSubscription" class="label">Stato Abbonamento Manuale</label>
          <select formControlName="manualSubscription" id="manualSubscription">
            <option value="">Scegli un'opzione</option>
            <option value="ABBONATO">ABBONATO</option>
            <option value="EXABBONATO">EX ABBONATO</option>
          </select>
        </div>
        <div class="input-field">
          <label class="label" for="expDateManual">Scadenza Abbonamento Manuale</label>
          <input type="date" id="expDateManual" formControlName="expDateManual">
        </div>
        <!-- <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="fhsub">Iscrizione a Family+Happy</label>
          <input type="checkbox" id="fhsub" formControlName="fhsub">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="fhsubmanual">Iscrizione a Family+Happy Manuale</label>
          <input type="checkbox" id="fhsubmanual" formControlName="fhSubManual">
        </div>
        <div *ngIf="!form.get('info.fhSubManual')!.value"></div>
        <div class="input-field" *ngIf="form.get('info.fhSubManual')!.value">
          <label class="label" for="expdate">Scadenza iscrizione</label>
          <input type="date" id="expdate" formControlName="expDate">
        </div> -->
        <div *ngIf="colf !== undefined">
          <label class="label" for="subInterest">ID Colf:</label>
          <p style="font-weight: bold;">{{colf.docRef}}</p>
        </div>
        <div style="grid-column: 1/3;" class="input-field">
          <label class="label" for="description">Descrizione</label>
          <textarea style="grid-column: 1/3;width: 100%;" rows="6" id="description" maxlength="426"
            formControlName="description"></textarea>
        </div>
        <div style="grid-column: 1/3;" class="input-field">
          <label class="label" for="note">Note</label>
          <textarea style="grid-column: 1/3;width: 100%;" rows="6" id="note" maxlength="2048"
            formControlName="notes"></textarea>
        </div>
      </div>
      <!-- ---------------------------------------EXPERIENCES--------------------------------------- -->
      <div *ngIf="step === 1" class="grid" style="grid-template-columns: 1fr 1fr; gap: 2em;" formGroupName="exp">
        <div
          style="display: flex; flex-direction: column; gap: 10px; align-items: flex-start;">
          <div>
            <label class="label label-highlight" for="total">Anni di esperienza totali</label>
            <input type="number" id="total" min=0 formControlName="total">
          </div>
        </div>
        <div>
          <label class="label label-highlight" for="employer">Datore di lavoro</label>
          <input type="string" id="employer" min=0 formControlName="employer">
        </div>
        <!-- <div formGroupName="cluster2"
          style="display: flex; flex-direction: column; gap: 10px; align-items: flex-start;">
          <div>
            <label class="label label-highlight" for="cluster2Age">Anni di esperienza con anziani
              autosufficienti</label>
            <input type="number" id="cluster2Age" min=0 formControlName="expYears">
          </div>
          <div *ngIf="form.get('exp.cluster2.expYears')?.value"
            style="display: flex; flex-direction: column; gap: 10px;">
            <div>
              <label class="label" for="cluster2Employer">Presso</label>
              <select id="cluster2Employer" formControlName="employer">
                <option value="Casa di cura/RSA">Casa di cura/RSA</option>
                <option value="Famiglia privata">Famiglia privata</option>
              </select>
            </div>
            <div formGroupName="tasks" style="display: flex; flex-direction: column; gap: 10px;">
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="assistance">Assistenza e compagnia</label>
                <input type="checkbox" id="assistance" formControlName="assistance" name="assistance">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="care">Cura personale e igiene</label>
                <input type="checkbox" id="care" formControlName="care" name="care">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="drugs">Somministrazione di medicinali</label>
                <input type="checkbox" id="drugs" formControlName="drugs" name="drugs">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="cooking">Preparazione pasti</label>
                <input type="checkbox" id="cooking" formControlName="cooking" name="cooking">
              </div>
              <div style="display: flex; justify-content: space-between;"
                *ngIf="form.get('exp.cluster2.employer')?.value === 'Famiglia privata'">
                <label class="label" for="housing">Aiuto faccende domestiche</label>
                <input type="checkbox" id="housing" formControlName="housing" name="housing">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="other">Utilizzo degli ausili</label>
                <input type="checkbox" id="other" formControlName="other" name="other">
              </div>
            </div>
            <div class="input-field" *ngIf="form.get('exp.cluster2.tasks.other')?.value">
              <input type="text" formControlName="help" id="help2">
            </div>
          </div>
        </div> -->
      </div>

      <!-- ----------------------------REFERENCES-------------------------------------- -->
      <div *ngIf="step === 2" class="grid" formGroupName="reference">
        <div>
          <label class="label" for="f_surname">Cognome prima famiglia</label>
          <input type="text" id="f_surname" formControlName="f_surname">
        </div>
        <div>
          <label class="label" for="f_second_surname">Cognome seconda famiglia</label>
          <input type="text" id="f_second_surname" formControlName="f_second_surname">
        </div>
      </div>
      <!-- ----------------------------LANG--------------------------------- -->
      <div *ngIf="step === 3" class="grid" formGroupName="lang">
        <div>
          <label class="label" for="title">Titolo di studio</label>
          <select formControlName="title">
            <option value="Medie inferiori">Medie inferiori</option>
            <option value="Medie superiori">Medie superiori</option>
            <option value="Laurea">Laurea</option>
          </select>
        </div>
        <div [ngStyle]="{'opacity': form.get('lang.title')?.value !== 'Laurea' ? '70%' : '100%'}">
          <label class="label" for="graduationType">Tipo di laurea</label>
          <input type="text" id="graduationType" formControlName="graduationType" />
        </div>
        <!-- <fieldset style="width: 100%;" formGroupName="specialities">
          <legend>Qualifiche speciali</legend>
          <div class="input-field" style="grid-template-columns: 1fr 20px;">
            <label for="glycemia">Controllo glicemia con apposito apparecchio</label>
            <input type="checkbox" id="glycemia" formControlName="glycemia" name="glycemia">
            <label for="injections">Iniezioni sicure</label>
            <input type="checkbox" id="injections" formControlName="injections" name="injections">
            <label for="dressings">Medicazioni</label>
            <input type="checkbox" id="dressings" formControlName="dressings" name="dressings">
            <label for="technology">Utilizzo di apparecchi tecnologici es. ricette elettroniche</label>
            <input type="checkbox" id="technology" formControlName="technology" name="technology">
          </div>
        </fieldset> -->
        <div>
          <label class="label" for="english">Inglese</label>
          <select formControlName="english">
            <option value="Non lo parlo">Non lo parlo</option>
            <option value="Sufficiente">Sufficiente</option>
            <option value="Ottimo">Ottimo</option>
            <option value="Madrelingua">Madrelingua</option>
          </select>
        </div>
        <div>
          <label class="label" for="italian">Italiano</label>
          <select formControlName="italian">
            <option value="Non lo parlo">Non lo parlo</option>
            <option value="Sufficiente">Sufficiente</option>
            <option value="Ottimo">Ottimo</option>
            <option value="Madrelingua">Madrelingua</option>
          </select>
        </div>
        <div>
          <label class="label" for="spanish">Spagnolo</label>
          <select formControlName="spanish">
            <option value="Non lo parlo">Non lo parlo</option>
            <option value="Sufficiente">Sufficiente</option>
            <option value="Ottimo">Ottimo</option>
            <option value="Madrelingua">Madrelingua</option>
          </select>
        </div>
        <div>
          <label class="label" for="spanish">Parli altre lingue?</label>
          <input class="form-input" type="text" id="otherlang1" formControlName="other1" placeholder="Altra lingua 1">
          <div></div>
          <input class="form-input" type="text" id="otherlang2" formControlName="other2" placeholder="Altra lingua 2">
        </div>
        <!-- <fieldset style="width: 100%;">
          <legend>Certificati</legend>
          <div class="input-field" style="grid-template-columns: 1fr 20px;">
            <div style="display: flex; flex-direction: column; gap: 10px;">
              <div *ngFor="let formControl of getCertificatesFormControls(), index as i">
                <label class="label" for="otherCert-{{ i + 1 }}">Certificato {{ i + 1 }}</label>
                <input type="text" [formControl]="$any(formControl)" id="otherCert-{{ i + 1 }}" />
              </div>
              <div style="display: flex; gap: 15px; align-items: flex-end;">
                <button class="quantity-button secondary-button" (click)="changeCertificatesLength(true)">+</button>
                <button class="quantity-button secondary-button" *ngIf="form.get('lang.certificates')?.value.length > 1"
                  (click)="changeCertificatesLength(false)">-</button>
              </div>
            </div>
          </div>
        </fieldset> -->
      </div>
      <div *ngIf="step === 4" class="grid" formGroupName="avail">
        <fieldset style="width: 100%;">
          <legend>Ruoli</legend>
          <div class="input-field" style="grid-template-columns: 1fr 20px;">
            <label class="label" for="houseCleaning">Pulizia e lavori domestici</label>
            <input type="checkbox" id="houseCleaning" formControlName="houseCleaning" name="houseCleaning">
            <label class="label" for="ironing">Stiro</label>
            <input type="checkbox" id="ironing" formControlName="ironing" name="ironing">
            <label class="label" for="cooking">Preparazione pasti </label>
            <input type="checkbox" id="cooking" formControlName="cooking" name="cooking">
            <label class="label" for="sewing">Cucito/ Aggiustamento abiti</label>
            <input type="checkbox" id="sewing" formControlName="sewing" name="sewing">
            <label class="label" for="groceries">Aiuto con la spesa</label>
            <input type="checkbox" id="groceries" formControlName="groceries" name="groceries">
            <label class="label" for="windowCleaning">Pulizie Tapparelle/ zanzariere/ vetri</label>
            <input type="checkbox" id="windowCleaning" formControlName="windowCleaning" name="windowCleaning">
            <label class="label" for="terraceCleaning">Pulizia Terrazzi</label>
            <input type="checkbox" id="terraceCleaning" formControlName="terraceCleaning" name="terraceCleaning">
            <label class="label" for="washingMachines">Lavatrici</label>
            <input type="checkbox" id="washingMachines" formControlName="washingMachines" name="washingMachines">
            <label class="label" for="gardening">Giardinaggio</label>
            <input type="checkbox" id="gardening" formControlName="gardening" name="gardening">
            <label class="label" for="helping">Aiuto Commissioni</label>
            <input type="checkbox" id="helping" formControlName="helping" name="helping">
          </div>
        </fieldset>
        <fieldset style="width: 100%;">
          <legend>Generiche</legend>
          <div class="input-field" style="grid-template-columns: 1fr 20px;">
            <label class="label" for="license">Patente</label>
            <input type="checkbox" id="license" formControlName="license">
            <label class="label" for="car">Automunita</label>
            <input type="checkbox" id="car" formControlName="car">
            <label class="label" for="smoker">Fumatrice</label>
            <input type="checkbox" id="smoker" formControlName="smoker">
            <label class="label" for="coliving">Disp. Convivenza</label>
            <input type="checkbox" id="coliving" formControlName="coliving">
            <label class="label" for="relocation">Disp. Trasferte</label>
            <input type="checkbox" id="relocation" formControlName="relocation">
          </div>
        </fieldset>
        <div style="grid-template-columns: unset; grid-column: 1/2; margin-top: 20px;" formGroupName="days">
          <fieldset style="width: 100%;">
            <legend>Giorni disponibili</legend>
            <div class="input-field" style="grid-template-columns: 1fr 20px;">
              <label class="label" for="sun">Domenica</label>
              <input type="checkbox" id="sun" formControlName="sun">
              <label class="label" for="mon">Lunedì</label>
              <input type="checkbox" id="mon" formControlName="mon">
              <label class="label" for="tue">Martedì</label>
              <input type="checkbox" id="tue" formControlName="tue">
              <label class="label" for="wed">Mercoledì</label>
              <input type="checkbox" id="wed" formControlName="wed">
              <label class="label" for="thu">Giovedì</label>
              <input type="checkbox" id="thu" formControlName="thu">
              <label class="label" for="fri">Venerdì</label>
              <input type="checkbox" id="fri" formControlName="fri">
              <label class="label" for="sat">Sabato</label>
              <input type="checkbox" id="sat" formControlName="sat">
            </div>
          </fieldset>
        </div>
        <div style="display: flex;flex-direction: column;align-items: start;margin-top: 20px;">
          <fieldset style="width: 100%;" formGroupName="hours">
            <legend>Orari di disponibilità</legend>
            <div class="input-field" style="grid-template-columns: 1fr 20px;">
              <label class="label" for="mor">Mattino</label>
              <input type="checkbox" id="mor" formControlName="mor">
              <label class="label" for="aft">Pomeriggio</label>
              <input type="checkbox" id="aft" formControlName="aft">
              <label class="label" for="eve">Sera</label>
              <input type="checkbox" id="eve" formControlName="eve">
            </div>
          </fieldset>
          <fieldset style="width: 100%;" formGroupName="contract">
            <legend>Interessi per incarichi</legend>
            <div class="input-field" style="grid-template-columns: 1fr 20px;">
              <label class="label" for="occ">Occasionali (2 ore a settimana)</label>
              <input type="checkbox" id="occ" formControlName="occ">
              <label class="label" for="part">Part-time (4 ore a settimana)</label>
              <input type="checkbox" id="part" formControlName="part">
              <label class="label" for="full">Full-time</label>
              <input type="checkbox" id="full" formControlName="full">
            </div>
          </fieldset>
        </div>
        <fieldset style="width: 100%;">
          <legend>Tariffa netta indicativa</legend>
          <label class="label" for="minWage">Tariffa minima</label>
          <input type="number" id="minWage" formControlName="minWage">
          <label class="label" for="maxWage">Tariffa massima</label>
          <input type="number" id="maxWage" formControlName="maxWage">
        </fieldset>
      </div>
      <app-caregiver-evaluation *ngIf="step === 5" [docRef]="colfID" [rating]="colf !== undefined ? colf.rating : defaultRating" (dataUpdated)="onCaregiverEvaluationChanged($event)"></app-caregiver-evaluation>
      <app-caregiver-engagement *ngIf="step === 6" [engagement]="colf !== undefined ? colf.engagement : defaultEngagement" [docRef]="colfID"></app-caregiver-engagement>
    </form>
    <div class="buttons-container">
      <button *ngIf="form.valid && form.touched && form.dirty" class="my-button" id="save-button"
        (click)="save(true)">Salva</button>
      <button *ngIf="!(form.valid && form.touched && form.dirty)" class="my-button disabled" id="save-button-disabled"
        disabled>Salva</button>
    </div>
  </div>
</div>

<app-alert *ngIf="showAlert" (save)="onAlertAction($event)" [data]="{ type: undefined }"
  [title]="'Attenzione, alcuni dati sono stati modificati!'" [message]="'Vuoi salvare le modifiche effettuate?'">
</app-alert>

<div class="modal" id="modal-container" *ngIf="showModal" (click)="dismiss($event)">
  <div class="modal-content">
    <app-image-cropper [imageSource]="sanitize(chosenPhoto)" (outputEvent)="getCroppedPhoto($event)">
    </app-image-cropper>
    <div class="modal-button-container">
      <button class="secondary-button" (click)="cropperAbort()">Annulla</button>
      <button class="secondary-button" (click)="uploadImage(finalPhoto, colf.docRef)">Conferma</button>
    </div>
  </div>
</div>


<app-cv-colf-template [colf]="colf" [generatePages]="generatePages" [currentPage]="currentPage"
  (sendPages)="cvPages = $event" style="position:absolute; z-index: -5; left: -1000px;"></app-cv-colf-template>

