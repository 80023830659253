import { cellFormatter } from './../../shared/utils.functions';
import { RequestMessagesComponent } from './../request-messages/request-messages.component';
import { RouterStateManagerService, RequestState } from '../../shared/services/router-state-manager/router-state-manager.service';
import { Request, RequestPersonalInfo, RequestDetail, RequestPetsitterDetail, RequestNurseDetail, ColfRequestDetail, FHService, RequestOperationalInfo } from 'npx-family-happy-common';
import { ModalService } from 'npx-family-happy-common';
import { RequestService } from '../request-service/request.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { catchError, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { Router } from '@angular/router';
import { RequestPetsitterService } from '../request-petsitter-service/request-petsitter.service';
import { RequestNurseService } from '../request-nurse-service/request-nurse.service';
import { RequestMessagesTemplatingService } from '../request-messages-templating/request-messages-templating.service';
import { GoogleMapsService } from '../caregiver-scoreboard/caregiver-selection/caregiver-selection-service/google-maps.service';
import { RequestColfService } from '../request-colf-service/request-colf.service';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { RequestPersonalInfoComponent } from '../request-personal-info/request-personal-info.component';
import { RequestBabysitterDetailFormComponent } from '../request-babysitter-detail-form/request-babysitter-detail-form.component';
import { RequestPetsitterDetailFormComponent } from '../request-petsitter-detail-form/request-petsitter-detail-form.component';
import { RequestNurseDetailFormComponent } from '../request-nurse-detail-form/request-nurse-detail-form.component';
import { RequestColfDetailFormComponent } from '../request-colf-detail-form/request-colf-detail-form.component';
import { RequestOperationalInfoComponent } from '../request-operational-info/request-operational-info.component';
import { RequestUpdatesComponent } from '../request-updates/request-updates.component';
import { AlertComponent } from 'src/app/shared/components/modals/alert/alert.component';
import { RequestNpsComponent } from 'src/app/requests-handling/request-nps/request-nps.component';

@Component({
  selector: 'app-request-detail',
  templateUrl: './request-detail.component.html',
  styleUrls: ['./request-detail.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatExpansionModule, RequestPersonalInfoComponent,
    RequestBabysitterDetailFormComponent, RequestPetsitterDetailFormComponent, RequestNurseDetailFormComponent, RequestColfDetailFormComponent,
    RequestOperationalInfoComponent, RequestMessagesComponent, RequestUpdatesComponent, AlertComponent, RequestNpsComponent
  ]
})
export class RequestDetailComponent implements OnInit, OnDestroy {
  step = 0;
  request!: Request;
  showAlert = false;
  requestOperatorState = {
    updateRequired: false,
    old: '',
    new: ''
  }
  requestStatusState = {
    updateRequired: false,
    old: '',
    new: ''
  }
  updateRequired = false;

  petsitterDetail!: RequestPetsitterDetail;
  nurseDetail!: RequestNurseDetail;
  colfDetail!: ColfRequestDetail;
  reqService!: FHService

  script: string = '';

  //filters of Babysitter filter modal
  filters: any = {};

  isNew = true;
  private nextTab = -1;
  private requestState!: RequestState;
  private initReq!: Request;
  private goBackRequested = false;

  private unsubscribe = new Subject<void>();
  private oldAddress!: string | undefined

  constructor(
    private requestService: RequestService,
    private router: Router,
    private stateManager: RouterStateManagerService,
    private modalService: ModalService,
    private requestPetsitterService: RequestPetsitterService,
    private requestNurseService: RequestNurseService,
    private requestColfService: RequestColfService,
    private messageTemplating: RequestMessagesTemplatingService,
    private googleMapsService: GoogleMapsService) {
  }

  ngOnInit(): void {
    this.messageTemplating.script.pipe(takeUntil(this.unsubscribe)).subscribe((script) => this.script = script);
    this.stateManager.getRequestState()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((state) => {
        this.requestState = state;
        if (this.requestState.step >= 0) {
          this.step = this.requestState.step;
        }
        if (this.requestState.babysitterFilters) {
          this.filters = this.requestState.babysitterFilters;
        }
        if (this.requestState.unsavedChanges && this.requestState.request) {
          this.isNew = this.requestState.isNew;
          this.updateRequired = this.requestState.unsavedChanges;
          this.setRequest(this.requestState.request);
        } else if (this.requestState.request && this.requestState.doNotLoadFromServer) {
          this.isNew = this.requestState.isNew;
          this.setRequest(this.requestState.request);
        } else {
          let requestID = '';
          let trash = false;
          if (!this.requestState.id.match('null')) {
            // Se l'id è presente all'interno dello stato, lo prendo dallo stato
            const temp = this.requestState.id.split('#');
            if (temp) {
              trash = temp[0] === 'T';
              requestID = temp[1];
            }
          } else {
            // Se l'id non è presente all'interno dello stato, lo prendo dalla route
            this.requestState.route = decodeURIComponent(this.router.url);
            const temp = this.requestState.route.split('?trash=');
            if (temp) {
              trash = temp[1] === 'true';
              requestID = temp[0].split('/')[2];
            }
          }
          if (requestID) {
            this.isNew = false;
            this.requestService.getRequestByID(requestID).pipe(takeUntil(this.unsubscribe))
              .subscribe((request) => {
                if (request) {
                  if (request.requestPersonalInfo.latitude === null || request.requestPersonalInfo.longitude === null) {
                    this.modalService.showErrorMessage("Attenzione, non ci sono coordinate per questa richiesta, controlla che l'indirizzo sia corretto e salvalo di nuovo!")
                  }
                  this.reqService = request.requestPersonalInfo.serviceLinked
                  if (this.request === undefined) { // Check whether the state has not been initialized yet
                    // Update State to load Script
                    this.updateRequired = false;
                    this.requestState.unsavedChanges = false;
                    this.initReq = request //req on init (without changes)
                    this.requestState.request = request;
                    this.stateManager.setRequestState(this.requestState);
                  }
                  this.setRequest(request);
                  if (request.requestPersonalInfo.requestCategory === 'PETSITTER') {
                    // Se è una richiesta petsitter, carichi i dati dei dettagli da DB
                    this.loadPetsitterDetail();
                  }
                  if (request.requestPersonalInfo.requestCategory === 'NURSE') {
                    // Se è una richiesta badanti, carichi i dati dei dettagli da DB
                    this.loadNurseDetail();
                  }
                  if (request.requestPersonalInfo.requestCategory === 'COLF') {
                    this.loadColfDetail();
                  }
                }
              });
          } else {
            this.requestService.getNewRequestTemplate()
              .pipe(takeUntil(this.unsubscribe))
              .subscribe((request) => {
                this.setRequest(request);
              })
          }
        }
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.stateManager.setRequestState(this.requestState, false);
    // this.caregiverSelectionService.resetFilters(this.request.requestPersonalInfo.requestCategory, true)
  }

  async updateRequest(showMessage?: boolean) {
    // Update request timestamp
    this.request.timestamp = new Date();

    // Verify if we need to post a request update because the status of the request changed
    this.postStatusRequestUpdate().pipe(
      takeUntil(this.unsubscribe),
      tap((request) => {
        // Verify if we need to post a request update because the operator handling the request changed
        this.postOperatorRequestUpdate();
      }),
      tap((request) => {
        // If needed update also specific RequestDetails
        if (this.request.requestPersonalInfo.requestCategory === 'PETSITTER') {
          this.requestPetsitterService.updatePetsitterDetail(this.request.id, this.petsitterDetail)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((detail) => {
              this.petsitterDetail = detail;
              this.stateManager.setRequestDetail(this.petsitterDetail);
              this.requestService.setPetsitterRequestDetail(this.petsitterDetail);
            })
        }

        if (this.request.requestPersonalInfo.requestCategory === 'NURSE') {
          this.requestNurseService.updateNurseDetail(this.request.id, this.nurseDetail)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((detail) => {
              this.nurseDetail = detail;
              this.stateManager.setRequestDetail(this.nurseDetail);
              this.requestService.setNurseRequestDetail(this.nurseDetail);
            })

        }

        if (this.request.requestPersonalInfo.requestCategory === 'COLF') {
          this.requestColfService.updateColfDetail(this.request.id, this.colfDetail)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((detail) => {
              this.colfDetail = detail;
              this.requestService.setColfRequestDetail(this.colfDetail);
            })

        }
      }),
      switchMap((_) => {
        //TAKING COORDINATES FROM ADDRESS---------------------------------------------------
        let request;
        if (this.request.requestPersonalInfo.parentAddress) {
          try {
            // let coordinates = await this.getCoordinates(this.request)
            request = {
              ...this.request,
              requestPersonalInfo: {
                ...this.request.requestPersonalInfo,
                // latitude: coordinates.latitude,
                // longitude: coordinates.longitude,
              }
            };
          } catch (err) {
            if (err === 'ZERO_RESULTS') {
              request = this.request;
            }
          }
        } else {
          request = this.request;
        }

        return this.requestService.updateRequest(request);
      }),
      catchError((err) => {
        this.modalService.showErrorMessage(`Si è verificato un errore nell'aggiornamento della richiesta.`)
        return of(null);
      })
    ).subscribe((res) => {
      if (showMessage) {
        this.modalService.showSuccessMessage('Richiesta modificata correttamente.');
      }
      this.updateRequired = false;
      this.requestState.unsavedChanges = false;
      this.requestState.request = this.request;
      this.stateManager.setRequestState(this.requestState);
    });
  }

  async createRequest() {
    try {
      //TAKING COORDINATES BEFORE TO SAVE NEW REQ
      let request;
      if (this.request.requestPersonalInfo.parentAddress) {
        let coordinates = await this.getCoordinates(this.request)
        request = {
          ...this.request,
          requestPersonalInfo: {
            ...this.request.requestPersonalInfo,
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
            parentEmail: this.request.requestPersonalInfo.parentEmail ? cellFormatter(this.request.requestPersonalInfo.parentEmail) : null,
            parentPhone: this.request.requestPersonalInfo.parentPhone ? cellFormatter(this.request.requestPersonalInfo.parentPhone) : null,
          }
        };
      } else {
        request = this.request;
      }

      this.requestService.createRequest(request)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.modalService.showSuccessMessage('Richiesta creata correttamente.');
          const result = /\d+/.exec(res.message);
          if (result) {
            this.request.id = +(result[0]);
            window.location.href = '/request-detail/' + this.request.id;
          }
        });
    } catch (err) {
      this.modalService.showErrorMessage('Si è verificato un errore nella creazione della richiesta');
    }
  }

  changeTab(tab: number) {
    if (this.updateRequired) {
      this.showAlert = true;
      this.nextTab = tab;
    } else {
      this.step = tab;
      this.requestState.step = this.step;
      this.nextTab = -1;
    }
    if (this.step === 3) {
      if (this.request.requestPersonalInfo.parentAddress === null || !this.request.requestPersonalInfo.parentAddress.match(/(?:[^,]+,){2,3}\s\w+$/gm)) {
        this.modalService.showErrorMessage(`Attenzione! Sembra che l'indirizzo del genitore non sia ancora stato compilato ed è un campo obbligatorio.`);
        this.changeTab(0);
      }
      this.stateManager.setRequestState(this.requestState);
    }
  }

  goBack() {
    if (this.updateRequired) {
      this.showAlert = true;
      this.goBackRequested = true;
    } else {
      this.performGoBack();
    }
  }

  onSubmit(save: boolean) {
    if (save && !this.isNew) {
      this.updateRequest();
    } else if (save && this.isNew) {
      this.createRequest();
    } else if (!save && this.goBackRequested) {
      this.performGoBack();
    } else {
      if (this.nextTab !== -1) {
        this.updateRequired = false
        this.requestState.unsavedChanges = false
        this.requestState.request = this.initReq;
        this.requestState.step = this.nextTab
        this.stateManager.setRequestState(this.requestState);
      }
    }
    this.showAlert = false;
  }

  /* START GENERIC METHODS */

  async updateRequestPersonalInfo(data: { requestID: number, requestPersonalInfo: RequestPersonalInfo, oldAddress: string }) {
    if (this.request.requestPersonalInfo.operator === '' || this.request.requestPersonalInfo.operator !== data.requestPersonalInfo.operator) {
      this.requestOperatorState = {
        updateRequired: true,
        old: this.request.requestPersonalInfo.operator ?? '',
        new: data.requestPersonalInfo.operator
      }
      // this.request.operationalInfo.status = 'PRESA IN CARICO'
    }
    this.oldAddress = data.oldAddress //to remove-------------(script)
    this.updateRequired = true;
    this.requestState.unsavedChanges = true;
    this.request.id = data.requestID;
    this.request.requestPersonalInfo = data.requestPersonalInfo;
    this.request.timestamp = new Date();
    this.setRequest(this.request);
    this.stateManager.setRequestState(this.requestState, false);
  }

  async updateCoordinates(addressFormatted: any) {
    this.request.requestPersonalInfo.parentAddress = addressFormatted
    let coordinates = await this.getCoordinates(this.request)
    this.request.requestPersonalInfo.latitude = coordinates.latitude
    this.request.requestPersonalInfo.longitude = coordinates.longitude
  }

  updateRequestDetailInfo(data: RequestDetail) {
    this.request.requestDetail = data;
    this.request.timestamp = new Date();
    this.updateRequired = true;
    this.requestState.unsavedChanges = true;
  }

  updatePetsitterRequestDetailInfo(data: RequestPetsitterDetail) {
    this.petsitterDetail = data;
    this.request.timestamp = new Date();
    this.updateRequired = true;
    this.requestState.unsavedChanges = true;
  }

  updateNurseRequestDetailInfo(data: RequestNurseDetail) {
    this.nurseDetail = data;
    this.request.timestamp = new Date();
    this.updateRequired = true;
    this.requestState.unsavedChanges = true;
  }

  updateColfRequestDetailInfo(data: ColfRequestDetail) {
    this.colfDetail = data;
    this.request.timestamp = new Date();
    this.updateRequired = true;
    this.requestState.unsavedChanges = true;
  }

  updateRequestOperationalInfo(data: RequestOperationalInfo) {
    if (!this.requestStatusState.updateRequired) {
      this.requestStatusState = {
        updateRequired: true,
        old: this.request.operationalInfo.status,
        new: data.status
      }
    }
    if (this.requestStatusState.updateRequired && this.requestStatusState.new !== data.status) {
      this.requestStatusState.new = data.status;
    }
    if (this.requestStatusState.updateRequired && this.requestStatusState.new === this.requestStatusState.old) {
      this.requestStatusState = {
        updateRequired: false,
        old: '',
        new: ''
      }
    }
    this.updateRequired = true;
    this.requestState.unsavedChanges = true;
    this.request.timestamp = new Date();
    this.request.operationalInfo = data;
    this.setRequest(this.request);
    this.stateManager.setRequestState(this.requestState, false);
  }

  getScript() {
    if (this.request.requestPersonalInfo.serviceLinked)
      return this.messageTemplating.processMessage(this.request.requestPersonalInfo.serviceLinked.script);
    else
      return '';
  }

  private performGoBack() {
    this.stateManager.resetRequestState();
    this.router.navigate(['/request-list']);
  }

  private setRequest(request: Request) {
    this.request = request;
    this.requestState.request = this.request;
    this.requestState.step = this.step;
    this.requestState.babysitterFilters = this.filters;
    this.requestState.isNew = this.isNew;
  }

  private postOperatorRequestUpdate() {
    if (this.requestOperatorState.updateRequired) {
      let newOperatorMessage: string;
      if (this.requestOperatorState.old !== '') {
        newOperatorMessage = `La richiesta, precedentemente gestita da ${this.requestOperatorState.old.toUpperCase()}, è stata presa in carico da ${this.requestOperatorState.new.toUpperCase()}.`;
      } else {
        newOperatorMessage = `La richiesta è stata presa in carico da ${this.requestOperatorState.new.toUpperCase()}.`
      }
      this.requestService.postRequestUpdate(this.request.id, newOperatorMessage, new Date())
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => this.requestOperatorState.updateRequired = false)
    }
  }

  private postStatusRequestUpdate() {
    if (this.requestStatusState.updateRequired) {
      // Change status with new endpoint change-status
      return this.requestService.changeStatus(this.request.id, this.requestStatusState.new).pipe(tap((request) => {
        // Status reset, because updates have been saved in database
        this.requestStatusState.updateRequired = false;
        this.request.operationalInfo.status = request.operationalInfo.status;
        this.request.operationalInfo.statusUpdated = request.operationalInfo.statusUpdated;
      }));
    } else {
      return of(this.request);
    }
  }

  private loadPetsitterDetail() {
    this.requestPetsitterService.getPetsitterDetail(this.request.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((detail) => {
        this.petsitterDetail = detail;
        if (!this.requestState.requestDetail) {
          this.stateManager.setRequestDetail(this.petsitterDetail);
        }
        this.requestService.setPetsitterRequestDetail(this.petsitterDetail);
      })
  }

  private loadNurseDetail() {
    this.requestNurseService.getNurseDetail(this.request.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((detail) => {
        this.nurseDetail = detail;
        if (!this.requestState.requestDetail) {
          this.stateManager.setRequestDetail(this.nurseDetail);
        }
        this.requestService.setNurseRequestDetail(this.nurseDetail);
      })
  }

  private loadColfDetail() {
    this.requestColfService.getColfDetail(this.request.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((detail) => {
        this.colfDetail = detail;
        this.requestService.setColfRequestDetail(this.colfDetail);
      })
  }

  /* END GENERIC METHODS */
  //SCRIPT --- CONVERT ADDRESSES INTO COORDINATES
  private async getCoordinates(request: Request) {
    let res = await this.googleMapsService.getCoordinates(request.requestPersonalInfo.parentAddress).then((res) => {
      const lat = res[0].geometry.location.lat();
      const lng = res[0].geometry.location.lng();
      const coordinates = {
        latitude: lat,
        longitude: lng
      }
      return coordinates
    })
    return res
  }
}
